<template>
  <div>
    <v-container v-if="componentLoading">
      <v-row justify="center">
        <v-col xs="12" lg="6">
          <h3>
            Assign Lenders To Deal
            <v-progress-circular
              indeterminate
              class="ml-9"
              color="primary"
            ></v-progress-circular>
          </h3>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="!componentLoading">
      <v-row justify="center">
        <v-col xs="12" xl="6">
          <v-expansion-panels :value="0">
            <v-expansion-panel style="background-color: transparent">
              <v-expansion-panel-header>
                <h3>
                  Assign Lenders To Deal
                  <v-icon
                    class="ml-4"
               
           
                    >mdi-account-edit-outline</v-icon
                  >
                </h3>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-row>
                  <v-col>
                    <div>

                    Lender Type: <span class="filterLink" style="margin-left: 10px;" @click="filterLenders('private')">Private</span> - <span class="filterLink" @click="filterLenders('mic')">MIC</span> - <span class="filterLink" @click="filterLenders('institutional')">Institutional</span> - <span  class="filterLink" @click="filterLenders('other')">Other</span>
                    </div>
                    <div style="margin-top: 10px;">
                      Round Filter: <span style="margin-left: 10px;" class="filterLink" @click="filterRound('Round 1')">Round 1</span> - <span class="filterLink" @click="filterRound('Round 2')">Round 2</span> - <span class="filterLink" @click="filterRound('Round 3')">Round 3</span> - <span class="filterLink" @click="filterRound('Round 4')">Round 4</span> - <span class="filterLink" @click="filterRound('Hail Mary')">Hail Mary!</span>
                    </div>
                    <div style="margin-top: 10px;">
                      <span class="filterLink" @click="resetFilters">Reset Filters</span>
                    </div>
                    <div  class="filterExplain">
                      <p><i>Please note, when you filter, it RESETS your selections. If you want to filter by lender type AND a round, then select lender type first then the round. You only have selected what is visible on the screen. You cannot go between lender type filters to add lenders. </i></p>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-data-table
                      v-model="selectedLenders"
                      :headers="lenderHeaders"
                      :items="lenders"
                      :items-per-page="-1"
                      item-key="id"
                      :loading="tableLoading"
                      show-select
                      class="elevation-1"
                    >
                      <template v-slot:item.lenderType="props">
                        <span style="text-transform: uppercase">{{
                          props.item.lenderType
                        }}</span>
                      </template>
                      <template v-slot:item.lenderName="props">
                        <span style="text-transform: capitalize">{{
                          props.item.lenderName
                        }}</span>
                      </template>
                    </v-data-table>
                    <v-btn class="mt-5" @click="submitAssignedLenders"
                      >Assign Lenders To Deal</v-btn
                    >

                    <v-btn
                      class="mt-5 ml-4"
                      @click="
                        $router.push({ path: `/dashboard/deal/read/${dealId}` })
                      "
                      >Cancel</v-btn
                    >

                    <v-card v-if="error" class="danger mb-6">
                      <v-card-subtitle class="red--text"
                        >There was an error.</v-card-subtitle
                      >
                      <v-card-text class="red--text">{{ error }}</v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import API from "@/plugins/API";

export default {
  props: ["dealId"],
  data() {
    return {
      componentLoading: true,
      tableLoading: true,
      error: false,
      dealData: {},
      fullLenderList: [],
      lenders: [],
      selectedLenders: [],
      lenderHeaders: [
        {
          text: "Lender Name",
          align: "start",
          sortable: true,
          value: "lenderName",
        },
        {
          text: "Lender Type",
          align: "start",
          sortable: true,
          value: "lenderType",
        },
                {
          text: "Group Name",
          align: "start",
          sortable: true,
          value: "group_name",
        },
      ],
    };
  },
  methods: {
    filterLenders(criteria){

      this.lenders = Object.assign(this.fullLenderList)

      let filteredLenders = this.lenders.filter((lender)=>{



        if(lender.lenderType === criteria) {
          return lender;
        }

      })

      this.selectedLenders = []

      this.lenders = filteredLenders;

      // console.log(filteredLenders)

    },
    filterRound(round){
      
      let filteredLenders = this.lenders.filter((lender)=>{



        if(lender.group_name === round) {
          return lender;
        }

      })

      this.selectedLenders = []

      this.lenders = filteredLenders;

    },
    resetFilters(){
      this.selectedLenders = [];
      this.lenders = Object.assign(this.fullLenderList)
    },
    async submitAssignedLenders() {
      try {
        let res = await API().post("/deal/assignLenderDealPivot", {
          dealId: this.dealData.id,
          lenders: this.selectedLenders,
        });

        if (res.status == 201) {
          this.$router.push(`/dashboard/deal/read/${this.dealData.id}`);
        } else {
          this.error = true;
        }
      } catch (e) {
        console.log(e.response);
      }
    },

  },
  async mounted() {
    try {
      let deal = await API().get(`/deal/read/${this.dealId}`);

      if (deal.status == 200) {
        this.dealData = deal.data.recordset[0];

        let lenders = await API().get(
          `/lender/list?prov=${this.dealData.province}`
        );

        if (lenders.status == 200) {
          this.lenders = lenders.data.recordset;

          let assignedLenders = await API().get(
            `/deal/assignedLenders/${this.dealData.id}`
          );

          let assignedLenderIds = assignedLenders.data.map((value) => value.id);

    

          // This code block was to have the already selected lenders to show up with a checkmark, which we don't do anymore

          // assignedLenders.data.forEach((element) => {
          //   this.selectedLenders.push(element);
          // });

          // end of the code block note

          this.fullLenderList = this.lenders.filter(function (lender) {
            if (!assignedLenderIds.includes(lender.id) && lender.active == true) {
              return lender;
            }
          });

          this.lenders = this.fullLenderList;

          this.componentLoading = false;
          this.tableLoading = false;
        }
      }
    } catch (e) {
      console.log(e);
    }
  },
};
</script>


<style scoped>
.filterExplain{
  margin-top: 15px;
  font-size: .85rem;
}
.filterLink {
  
}
.filterLink:hover {
  color: yellow;
  cursor: pointer;
}
</style>